import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Chip, CircularProgress, Divider, IconButton, SwipeableDrawer, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import CloseIcon from '@mui/icons-material/Close';
// Functions/Constants/Enum
import * as actionTypes from 'store/action-types';
import { createAction } from 'shared/util/Utility';
import HttpService from 'shared/services/Http.service';
import AuthService from 'shared/services/Auth.service';
import MMSCANTooltip from 'shared/components/MMSCANToolTip/MMSCANToolTip';
import { API_CONFIG } from 'shared/constants/api';
import {
	FSActions,
	NotificationBGColor,
	NotificationActionFilter,
	NotificationType,
	ROUTES
} from 'shared/constants/constant';
// Type/Interface
import { IState } from 'shared/interface/state';
import { INotificationItem, labelBGIndex, labelKeyIndex } from '../Layout.types';
// Styles
import * as Sx from './Notification.styles';
import { colors } from 'assets/styles/MMSCANTheme';

type NotificationProps = {
	isOpen: boolean;
	unreadCount: number;
	handleNotificationToggle: () => void;
	handleUnreadCount: (count: number) => void;
};

const Notification: FC<NotificationProps> = ({ isOpen, unreadCount, handleNotificationToggle, handleUnreadCount }) => {
	const isCallAPI = useSelector((state: IState) => state.notification.isCallAPI);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const listRef = useRef<HTMLDivElement>(null);

	const [scrollPosition, setScrollPosition] = useState(0);
	const [notificationList, setNotificationList] = useState<INotificationItem[]>([]);
	const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
	const [notificationActionType, setNotificationActionType] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false);
	const [paginationParams, setPaginationParams] = useState({
		currentPage: 1,
		total: 0
	});

	// Fetch notifications from the API
	const fetchNotificationList = useCallback(
		async (
			page = paginationParams.currentPage,
			isRequiredOldData = false,
			action_type = notificationActionType
		) => {
			try {
				setLoading(true);
				const params = {
					current_page: page,
					record_per_page: 15,
					action_type
				};
				if (!isUndefined(AuthService.getAuthData())) {
					const response = await HttpService.get(API_CONFIG.notification, params);
					const { notification, pagination_data, unread_notification_count } = response.data;

					handleUnreadCount(unread_notification_count);
					setNotificationList((prevList) =>
						isRequiredOldData ? [...prevList, ...notification] : notification
					);
					setPaginationParams({ currentPage: pagination_data.current_page, total: pagination_data.total });
					setLoading(false);
					dispatch(createAction(actionTypes.NOTIFICATION_API_CALL, { isCallAPI: false }));

					// Scroll to current position
					setScrollPosition(listRef?.current?.scrollTop || 0);
					if (listRef.current) {
						listRef.current.scrollTo({
							top: listRef.current && !isRequiredOldData ? 0 : listRef?.current?.scrollTop,
							behavior: 'smooth'
						});
					}
				}
			} catch (error) {
				console.error(error);
				setLoading(false);
			}
		},
		[dispatch, handleUnreadCount, notificationActionType, paginationParams.currentPage]
	);

	// Mark all notifications as read
	const markAsReadNotification = useCallback(async () => {
		try {
			setLoading(true);
			const response = await HttpService.post(API_CONFIG.markAllAsRead);
			if (!response.is_error) {
				fetchNotificationList(1);
			}
			setLoading(false);
		} catch (error) {
			console.error(error);
			setLoading(false);
		}
	}, [fetchNotificationList]);

	// Handle notification redirection based on action
	const handleNotificationRedirection = (notificationDetails: INotificationItem) => {
		const { region, dealer_code, year, month, action_type, visit_id, action_item_id } = notificationDetails;

		// Function to close notification drawer and reset state
		const resetNotificationState = () => {
			handleNotificationToggle();
			setNotificationActionType('');
			setIsDrawerOpen(false);
		};

		if (FSActions.includes(action_type)) {
			dispatch(
				createAction(actionTypes.FS_FILTER, {
					region,
					dealer_code,
					year,
					month: month - 1
				})
			);
			navigate(ROUTES.FINANCIAL_STATEMENT);
			resetNotificationState();
			return;
		}

		if (action_type === 'visit') {
			action_item_id &&
				dispatch(
					createAction(actionTypes.AGENDA_ACTION, {
						screen: 1
					})
				);
			dispatch(
				createAction(actionTypes.ACTION_SCREEN, {
					screen: 'list'
				})
			);
			navigate(
				action_item_id
					? `${ROUTES.VISIT}/${visit_id}/${ROUTES.AGENDA_ACTION}/view/${ROUTES.ACTION}/${action_item_id}/view`
					: `${ROUTES.VISIT}/${visit_id}/${ROUTES.AGENDA_ACTION}/view`
			);
			resetNotificationState();
		}
	};

	// Mark a specific notification as read
	const handleReadNotification = async (id: string) => {
		try {
			await HttpService.post(`${API_CONFIG.readNotification}/${id}`).then(() => {
				setNotificationList((prevList) =>
					prevList.map((item) => ({
						...item,
						is_read: item.notification_id === id ? true : item.is_read
					}))
				);
				handleUnreadCount(unreadCount - 1);
			});
		} catch (error) {
			console.error(error);
		}
	};

	const handleNotificationFilter = (label: string) => {
		const notificationType = NotificationType[label as labelKeyIndex];
		setNotificationActionType(notificationType);
		setPaginationParams({ ...paginationParams, currentPage: 1 });
		setNotificationList([]);
		fetchNotificationList(1, false, notificationType);
	};

	const handleCloseNotification = () => {
		handleNotificationToggle();
		setNotificationActionType('');
	};

	useEffect(() => {
		if (listRef.current) {
			listRef.current.scrollTo({
				top: scrollPosition
			});
		}
	}, [scrollPosition]);

	useEffect(() => {
		fetchNotificationList();
	}, []);

	useEffect(() => {
		setIsDrawerOpen(isOpen);
		return () => {
			fetchNotificationList();
		};
	}, [isOpen]);

	useEffect(() => {
		if (isCallAPI) fetchNotificationList();
	}, [fetchNotificationList, isCallAPI]);

	return (
		<SwipeableDrawer
			anchor='right'
			open={isDrawerOpen}
			swipeAreaWidth={isDrawerOpen ? 500 : 0}
			PaperProps={{ sx: { width: '500px' } }}
			onClose={handleCloseNotification}
			onOpen={handleNotificationToggle}
		>
			<Box sx={Sx.notificationTitleBox}>
				<Typography sx={Sx.notificationTitle} data-testid='notificationTitle'>
					Notifications
				</Typography>
				<MMSCANTooltip title='Close' arrow>
					<IconButton onClick={handleCloseNotification} sx={{ padding: '0' }}>
						<CloseIcon />
					</IconButton>
				</MMSCANTooltip>
			</Box>
			<Divider />
			{isDrawerOpen && (
				<>
					<Box
						sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}
					>
						<Box>
							{NotificationActionFilter.map((label) => {
								const isChipSelected =
									notificationActionType === NotificationType[label as labelKeyIndex];
								const selectedType = NotificationType[label as labelKeyIndex];
								const selectedColor = NotificationBGColor[selectedType as labelBGIndex];
								return (
									<Chip
										key={label}
										label={label}
										color='primary'
										size='small'
										variant={isChipSelected ? 'filled' : 'outlined'}
										onClick={() => handleNotificationFilter(label)}
										sx={Sx.notificationChipSx(selectedColor, isChipSelected, label)}
									/>
								);
							})}
						</Box>
						<Typography
							sx={Sx.notificationStyles.markAllAsRead(unreadCount)}
							onClick={() => unreadCount > 0 && markAsReadNotification()}
						>
							Mark all as read
						</Typography>
					</Box>
					<Box sx={{ padding: '0 10px', height: 'calc(100vh - 85px)', overflow: 'auto' }} ref={listRef}>
						{!loading && isEmpty(notificationList) && (
							<Typography sx={Sx.notificationStyles.noDataText}>Notifications not available.</Typography>
						)}
						{notificationList.length > 0 &&
							notificationList.map((notification: INotificationItem, index: number) => {
								const { action_type, is_read, notification_id, notification_text, created_at } =
									notification;
								return (
									<Box
										key={index}
										sx={{
											...Sx.listItemStyle,
											backgroundColor:
												NotificationBGColor[action_type as labelBGIndex] ||
												colors.secondary.shade4
										}}
										data-testid='notificationListItem'
										onClick={() => {
											handleNotificationRedirection(notification);
											!is_read && handleReadNotification(notification_id);
										}}
									>
										<Typography
											sx={{ wordBreak: 'break-word', fontSize: '14px', lineHeight: '18px' }}
											dangerouslySetInnerHTML={{ __html: notification_text }}
										/>

										{!is_read && (
											<Box component={'span'} sx={Sx.notificationStyles.unreadIndicator} />
										)}
										<Typography
											sx={Sx.notificationBottomText}
											data-testid='notification-bottom-text'
										>
											{moment(created_at).format('dddd, YYYY-MM-DD')}
										</Typography>
									</Box>
								);
							})}
						{loading && (
							<Box textAlign={'center'} margin={'20px 0'}>
								<CircularProgress data-testid='loader' color='primary' />
							</Box>
						)}
						{!loading && (
							<Box
								sx={{
									width: '100%',
									padding: '5px',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center'
								}}
							>
								{paginationParams.total > notificationList.length && (
									<Typography
										sx={Sx.showMoreText}
										onClick={() => {
											fetchNotificationList(
												paginationParams.currentPage + 1,
												true,
												notificationActionType
											);
										}}
									>
										Show More
										<KeyboardArrowDownIcon sx={{ marginTop: '2px' }} />
									</Typography>
								)}
								{paginationParams.total <= notificationList.length && notificationList.length > 0 && (
									<Typography>Yay! You have seen it all</Typography>
								)}
							</Box>
						)}
					</Box>
				</>
			)}
		</SwipeableDrawer>
	);
};

export default Notification;
